import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { capitalize } from 'lodash';
import { useJourneyQuery } from '../../../../../../hooks/journeys/journeys';
import { useProgram } from '../../../../../../contexts/program';
import { LoadingChart } from '../../../../../../shared/Charts/LoadingChart';
import { NoDataChart } from '../../../../../../shared/Charts/NoDataChart';
import { useJourneyStepsInsightsMetricsQuery } from '../../../../../../hooks/journeyInsightsMetrics';
import { Step } from '../../../../../../models/journeys/journey';
import type { JourneyStepsInsightsMetric } from '../../../../../../services/api-journey-metrics';
import { MAIcon } from '../../../../../../shared/MAIcon';

import style from './tableau-table-widget.module.css';
import { DashboardFilterContext } from '../../contexts/DashboardFilterContext';

type StepData = {
  stepId: string;
  stepName?: string;
  stepType?: string;
  uniqueUsersEntered?: number;
  uniqueUsersExited?: number;
  uniqueUsersCurrent?: number;
  uniqueUsersSent?: number;
  uniqueUsersDelivered?: number;
  uniqueUsersError?: number;
  uniqueUsersInteracted?: number;
  uniqueUsersOpened?: number;
  interactionRate?: number;
};

const columnHelper = createColumnHelper<JourneyRow>();
const emptyArray: never[] = [];

type JourneyRow = StepData & {
  stepName?: string;
  stepType?: string;
  stepId: string;
  subRows: JourneyRow[] | undefined;
};

const buildJourneyRows = (
  journeySteps: Step[],
  metrics: { [stepId: string]: JourneyStepsInsightsMetric }
) => {
  const journeyMap: Record<string, Step> = {};
  journeySteps.forEach((step: Step) => {
    journeyMap[step.id] = step;
  });

  const firstEdge = journeySteps.find((step) => step.type === 'start')
    ?.next?.[0];

  if (firstEdge === undefined) {
    return null;
  }
  const currentStep = journeyMap[firstEdge.targetId];

  return currentStep
    ? buildJourneyRow(currentStep.id, journeyMap, metrics)
    : null;
};

const centeredCell = (value: string | number | undefined) => (
  <span className={style.centeredCell}>
    {value === undefined ? '-' : value}
  </span>
);

const buildJourneyRow = (
  targetId: string,
  journeyMap: Record<string, Step>,
  metrics: { [stepId: string]: JourneyStepsInsightsMetric }
): Array<JourneyRow> => {
  let next = [{ targetId }];
  const result = [];
  while (next.length !== 0) {
    const { targetId: nextTargetId } = next[0];
    const targetStep = journeyMap[nextTargetId];
    if (targetStep === undefined) {
      break;
    }
    let { name } = targetStep;
    const { type } = targetStep;
    if (name === undefined || name?.length === 0) {
      switch (type) {
        case 'delay':
          name = `Delay: ${targetStep.quantity}${targetStep.unit}`;
          break;
        case 'decision':
          name = `Decision: ${name}`;
          break;
        case 'communication':
          name ||= targetStep.title;
          break;
        case 'end':
        case 'start':
        default:
      }
    }
    if (type === 'decision') {
      for (let i = 0; i < targetStep.next.length; i += 1) {
        const { targetId: decisionTargetId, label } = targetStep.next[i];
        const decisionSteps = buildJourneyRow(
          decisionTargetId,
          journeyMap,
          metrics
        );
        const decisionRow = {
          uniqueUsersEntered: metrics[targetId]?.uniqueUsersEntered,
          uniqueUsersCurrent: 0,
          uniqueUsersExited:
            metrics[decisionTargetId]?.uniqueUsersExited ||
            metrics[targetId]?.uniqueUsersExited,
          stepId: `${targetStep.id}_${i}`,
          stepName: `Decision: ${label}`,
          stepType: targetStep.type,
          subRows: decisionSteps,
        };
        result.push(decisionRow);
      }
    } else if (type === 'end' || type === 'start') {
      return result;
    } else if (type === 'delay') {
      const row = {
        uniqueUsersEntered: metrics[targetStep.id]?.uniqueUsersEntered,
        uniqueUsersCurrent: metrics[targetStep.id]?.uniqueUsersCurrent,
        uniqueUsersExited: metrics[targetStep.id]?.uniqueUsersExited,
        stepId: targetStep.id,
        stepName: name,
        stepType: targetStep.type,
        subRows: undefined,
      };
      result.push(row);
      next = journeyMap[nextTargetId].next;
    } else {
      const row: JourneyRow = {
        ...metrics[targetStep.id],
        stepId: targetStep.id,
        stepName: name,
        stepType: targetStep.type,
        subRows: undefined,
      };
      result.push(row);
      next = journeyMap[nextTargetId].next;
    }
    // eslint-disable-next-line no-param-reassign
    delete journeyMap[nextTargetId];
  }
  return result;
};

export const JourneyStepOverviewInsightsTable: React.FC = () => {
  const { appliedFilters } = React.useContext(DashboardFilterContext);
  const journeyId = useMemo(() => {
    return appliedFilters.campaign_id?.value?.[0]?.split('|')?.[0];
  }, [appliedFilters]);

  if (!journeyId) return null;
  return <JourneyStepOverviewInsightsTableInner journeyId={journeyId} />;
};

const FILTER_MAP: Record<string, string> = {
  journey_entry_end_date: 'endDate',
  journey_entry_start_date: 'startDate',
};

interface ITableauTableWidget {
  journeyId: string;
}
export const JourneyStepOverviewInsightsTableInner: React.FC<ITableauTableWidget> = ({
  journeyId,
}) => {
  const { id: programId } = useProgram();
  const { appliedFilters } = React.useContext(DashboardFilterContext);

  const query = useMemo(() => {
    const result: { [key: string]: unknown } = {};
    Object.keys(FILTER_MAP).forEach((k) => {
      result[FILTER_MAP[k]] = appliedFilters[k]?.value;
    });
    return result;
  }, [appliedFilters]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('stepName', {
        header: () => <span>Step</span>,
        cell: ({ cell, row }) => {
          const v = cell.getValue();
          return (
            <span
              style={{
                paddingLeft: `${row.depth * 2}rem`,
              }}
            >
              {row.getCanExpand() ? (
                <button
                  type="button"
                  className={style.expandButton}
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? (
                    <MAIcon name="expand_more" />
                  ) : (
                    <MAIcon name="chevron_right" />
                  )}
                </button>
              ) : null}{' '}
              {v === undefined || v?.length === 0
                ? capitalize(row.original.stepType)
                : v}
            </span>
          );
        },
      }),
      columnHelper.accessor('uniqueUsersEntered', {
        header: () => <span>Entered</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('uniqueUsersCurrent', {
        header: () => <span>Current</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('uniqueUsersExited', {
        header: () => <span>Exited</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('uniqueUsersSent', {
        header: () => <span>Sent</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('uniqueUsersDelivered', {
        header: () => <span>Delivered</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('uniqueUsersError', {
        header: () => <span>Error</span>,
        cell: ({ cell }) => centeredCell(cell.getValue()),
      }),
      columnHelper.accessor('interactionRate', {
        header: () => <span>Interaction Rate</span>,
        cell: ({ row }) => {
          const { uniqueUsersInteracted, uniqueUsersDelivered } = row.original;
          const value =
            uniqueUsersInteracted !== undefined &&
            uniqueUsersDelivered !== undefined &&
            uniqueUsersDelivered !== 0
              ? `${Math.min(
                  (uniqueUsersInteracted / uniqueUsersDelivered) * 100,
                  100
                ).toFixed(0)}%`
              : '-';
          return centeredCell(value);
        },
      }),
    ],
    []
  );

  const { data: journey, isLoading } = useJourneyQuery({
    programId,
    journeyId: Number(journeyId),
  });

  const {
    data: journeyStepInsightsMetrics,
    isLoading: isLoadingStepInsightMetrics,
  } = useJourneyStepsInsightsMetricsQuery({
    journeyId,
    activationId: journey?.currentActivationId,
    query,
  });

  const data = useMemo(() => {
    if (journeyStepInsightsMetrics === undefined) {
      return [];
    }
    const journeySteps =
      journey?.liveGraph?.steps || journey?.draftGraph?.steps || [];

    return buildJourneyRows(journeySteps, journeyStepInsightsMetrics);
  }, [
    journeyStepInsightsMetrics,
    journey?.liveGraph?.steps,
    journey?.draftGraph?.steps,
  ]);

  const table = useReactTable({
    columns,
    data: data ?? emptyArray,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (isLoading || isLoadingStepInsightMetrics) {
    return <LoadingChart />;
  }

  if (
    journeyId === undefined ||
    (journey?.liveGraph?.steps || journey?.draftGraph?.steps) === undefined
  ) {
    return (
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          rowGap: '10px',
        }}
      >
        <NoDataChart />
      </div>
    );
  }

  return (
    <>
      <div className={style.titleContainer}>
        <h1 className={style.title}>Step Overview</h1>
        <p className={style.subtitle}>
          This table displays all the steps within the selected journey along
          with key performance metrics, helping you understand how each step is
          performing at a high level.
        </p>
      </div>
      <div className={style.tableauTableWidgetContainer}>
        <table className={style.tableauTableWidget}>
          <thead>
            {table.getHeaderGroups().map((hg) => {
              return (
                <tr key={hg.id}>
                  {hg.headers.map((h) => (
                    <th key={h.id}>
                      {flexRender(h.column.columnDef.header, h.getContext())}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
