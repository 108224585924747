import { DefinitionBlock, DynamicBlockFieldData } from 'models/publisher/block';
import React from 'react';
import { Box } from 'DesignSystem/Components';
import { Icon, IconButton } from '@socialchorus/shared-ui-components';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';

import cx from 'classnames';
import { Input } from 'DesignSystem/Form';
import { v4 as uuidv4 } from 'uuid';
import styles from './editor.module.css';
import { DefaultContentBlock } from './DefaultContentBlock';
import { useDynamicBlockEditor } from './useDynamicBlockEditor';

export const DynamicBlockEditor: React.FC<{
  block: DefinitionBlock<{
    dynamic_block: { uuid: string; type: 'dynamic_block' };
  }>;
  onLeave?: () => void;
  onChange?: (fieldName: string, data: DynamicBlockFieldData) => void;
}> = ({ block }) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const {
    currentVariant,
    dynamicBlockVariants,
    selectNextVariant,
    selectPreviousVariant,
    appendVariant,
    updateName,
    isReady,
  } = useDynamicBlockEditor(block, currentIndex, setCurrentIndex);

  const isFirstVariantSelected = currentIndex === 0;
  const isLastVariantSelected =
    currentIndex === dynamicBlockVariants.length - 1;

  if (!isReady) return null;

  const hasVariants = dynamicBlockVariants && dynamicBlockVariants.length > 0;
  // this should never happen
  if (!currentVariant || !hasVariants) return null;

  return (
    <Box
      width="100%"
      height={338}
      className={styles.wrapper}
      background={Text.background.grayTintLight}
    >
      <Box
        height={82}
        className={styles.header}
        background={Text.background.grayTintDark}
      >
        <Flex className={styles.containerName}>
          <Text.Caption semibold color={Text.color.gray40}>
            Dynamic Container
          </Text.Caption>
        </Flex>
        <Flex className={styles.controlsWrapper}>
          <Flex className={styles.navigationButtons}>
            <IconButton
              disabled={isFirstVariantSelected}
              className={cx(styles.controlsButton, {
                [styles.disabled]: isFirstVariantSelected,
              })}
              onClick={selectPreviousVariant}
              iconName="arrow_back"
              size="compact"
            />
            <Box>
              <Text.Body>
                {currentIndex + 1}/{dynamicBlockVariants.length}
              </Text.Body>
            </Box>
            <IconButton
              disabled={isLastVariantSelected}
              className={cx(styles.controlsButton, {
                [styles.disabled]: isLastVariantSelected,
              })}
              size="compact"
              onClick={selectNextVariant}
              iconName="arrow_forward"
            />
          </Flex>
          <Box width={362}>
            <Text.Caption color={Text.color.gray90}>
              {currentVariant.default ? (
                <Box padding={[0, 0, 0, 8]}>
                  <Text.Caption color={Text.color.gray90}>
                    Default Content
                  </Text.Caption>
                </Box>
              ) : (
                <Box className={styles.editableTitleWrapper}>
                  <Icon size={14}>edit</Icon>
                  <Input value={currentVariant.name} onChange={updateName} />
                </Box>
              )}
            </Text.Caption>
          </Box>
          <Flex className={styles.actionButtons}>
            <IconButton onClick={appendVariant} iconName="add" size="compact" />
            <IconButton iconName="edit" size="compact" />
            <IconButton
              disabled={currentVariant.default}
              className={styles.controlsButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                appendVariant(e, { ...currentVariant, uuid: uuidv4() })
              }
              iconName="content_copy"
              size="compact"
            />
          </Flex>
        </Flex>
      </Box>
      <Box height={256}>
        {hasVariants && currentVariant && !currentVariant.default && (
          <>
            <div>
              Render current variant here: name {currentVariant.name} uuid{' '}
              {currentVariant.uuid}
            </div>
          </>
        )}
        {hasVariants && currentVariant && currentVariant.default && (
          <DefaultContentBlock />
        )}
      </Box>
    </Box>
  );
};
