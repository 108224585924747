import { Button } from 'DesignSystem/Form/InputElements';
import { DateTime } from 'luxon';
import React from 'react';
import { Alert } from '@socialchorus/shared-ui-components';
import { DashboardFilterContext } from '../contexts/DashboardFilterContext';
import {
  DashboardFilter,
  DashboardFilterWrapper,
} from '../hooks/useDashboardFilters';
import { DashboardDateRangePicker } from './DashboardDatePicker';
import { DateRangePickerContainer } from './DashboardParameter';
import { DashboardFilterMapping } from '../../../../../services/api-insights';
import { useJourneyQuery } from '../../../../../hooks/journeys/journeys';
import { Flex } from '../../../../../DesignSystem/Layout/Flex';
import { Box } from '../../../../../DesignSystem/Components';
import styles from './dashboard-filter-bar.module.css';

export const DashboardFilterBar: React.FC<{
  onApply: (appliedFilters: Record<string, DashboardFilterMapping>) => void;
}> = ({ onApply }) => {
  const {
    updateFilterValue,
    appliedFilters,
    dashboardFilters,
    dashboardId,
    filters,
  } = React.useContext(DashboardFilterContext);

  const renderRequiredLabel = React.useCallback(() => {
    if (dashboardFilters[dashboardId] === undefined) return null;
    const requiredFilters = Object.keys(dashboardFilters[dashboardId]).filter(
      (x) =>
        dashboardFilters[dashboardId][x]?.required &&
        !dashboardFilters[dashboardId][x]?.value?.length
    );
    if (requiredFilters.length === 0) return null;

    return (
      <Flex start className={styles.alert}>
        <Alert
          title="Please choose an initiative to get started."
          size="default"
        />{' '}
      </Flex>
    );
  }, [dashboardId, dashboardFilters]);

  const renderFilterMappings = React.useCallback(() => {
    if (!filters) return null;
    const existingMapping = dashboardFilters[dashboardId];
    return Object.values(filters).map((p) => {
      if (p.filter_type === 'parameter') {
        // these two date pickers are special cases, since they handle more than 1
        // different filter values
        if (p.name === 'journeyDatePicker') {
          // check if dashboard has already had filter values set.
          const startValue = existingMapping?.journey_entry_start_date?.value;
          const endValue = existingMapping?.journey_entry_end_date?.value;
          return (
            <DashboardDateRangePicker
              key={p.name}
              onChange={updateFilterValue}
              start={{
                name: 'journey_entry_start_date',
                value: startValue
                  ? DateTime.fromFormat(startValue[0], 'MM/dd/yy')
                  : undefined,
              }}
              end={{
                name: 'journey_entry_end_date',
                value: endValue
                  ? DateTime.fromFormat(endValue[0], 'MM/dd/yy')
                  : undefined,
              }}
            />
          );
        }
        if (p.name === 'dateRange') {
          return (
            <DateRangePickerContainer
              key={p.name}
              onChange={updateFilterValue}
            />
          );
        }
        const value = existingMapping[p.filter_key]
          ? existingMapping[p.filter_key]?.value
          : undefined;
        return (
          <DashboardFilter
            key={p.filter_key}
            filter={p}
            onChange={updateFilterValue}
            initialValues={value}
          />
        );
      }
      if (p.filter_type === 'filter') {
        if (p.name === 'journeyStep') {
          const journeyId = existingMapping?.campaign_id?.value?.[0]?.split(
            '|'
          )?.[0];
          const startValue = existingMapping?.step_id?.value;
          return (
            <DashboardFilterWrapper
              key={p.name}
              onChange={updateFilterValue}
              queryHook={useJourneyQuery}
              queryHookProps={{ journeyId }}
              filterKey="step_id"
              label="Journey Step"
              multiSelect={false}
              valueAccessor="liveGraph.steps"
              valueTransform={(data: { [key: string]: string }) => {
                const { name, id } = data;
                if (name === undefined) {
                  return undefined;
                }
                return {
                  label: name,
                  value: id.toString(),
                };
              }}
              initialValues={startValue}
              hasSearchBar
            />
            // <></>
          );
        }
        if (p.name === 'timeRange') {
          const value =
            existingMapping?.month_year_filterable?.value === undefined
              ? [
                  DateTime.now()
                    .startOf('month')
                    .minus({ months: 1 })
                    .toLocaleString(DateTime.DATE_SHORT),
                ]
              : existingMapping?.month_year_filterable?.value;
          return (
            <DashboardFilter
              key={p.filter_key}
              filter={p}
              onChange={updateFilterValue}
              initialValues={value}
            />
          );
        }
        const value = existingMapping
          ? existingMapping[p.filter_key].value
          : undefined;
        return (
          <DashboardFilter
            key={p.filter_key}
            filter={p}
            onChange={updateFilterValue}
            initialValues={value}
          />
        );
      }
      return <></>;
    });
  }, [filters, dashboardFilters, dashboardId, updateFilterValue]);

  return (
    <Box>
      <Flex>
        {renderFilterMappings()}
        <Button
          type="button"
          label="Apply Filters"
          compact
          onClick={() => {
            onApply(appliedFilters);
          }}
        />
      </Flex>
      {renderRequiredLabel()}
    </Box>
  );
};
