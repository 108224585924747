import React from 'react';
import { Filter, DateFilter } from 'contexts/content/filters';
import { InitiativesFilter } from 'components/initiatives/InitiativeSelect/Filter';
import { TopicsFilter } from 'components/topics/TopicSelect/Filter';
import { Topic } from 'models/topic';
import { Author } from 'models/author';
import { AuthorFilter } from 'components/publisher/settings/SettingsEditor/sections/Administration/AuthorSelect/Filter';
import { DateRangeFilter } from './DateRangeFilter';
import { ContentType, ContentTypeFilter } from './ContentTypeFilter';
import { StudioVersionFilter } from './StudioVersionFilter';
import { PublicationStateFilter } from './PublicationStateFilter';
import { FlaggedFilter } from './FlaggedFilter';

type PropsType = {
  filter: Filter;
  setFilterValues: (
    name: string,
    value: Array<string | Author | Topic>
  ) => void;
  dropdownAlignment?: 'left' | 'right' | 'center';
  autoResponsive?: boolean;
};

export const FilterDropdown: React.FC<PropsType> = ({
  filter,
  setFilterValues,
  dropdownAlignment,
  autoResponsive = false,
}) => {
  const onAuthorChange = React.useCallback(
    (authors) => {
      setFilterValues('publishers', authors);
    },
    [setFilterValues]
  );

  const onCreatorChange = React.useCallback(
    (authors) => {
      setFilterValues('creators', authors);
    },
    [setFilterValues]
  );

  const onAuthorAliasChange = React.useCallback(
    (authorAliases) => {
      setFilterValues('author_aliases', authorAliases);
    },
    [setFilterValues]
  );

  const onTopicChange = React.useCallback(
    (topics) => {
      setFilterValues('topics', topics);
    },
    [setFilterValues]
  );

  const onInitiativeChange = React.useCallback(
    (initiatives) => {
      setFilterValues('initiatives', initiatives);
    },
    [setFilterValues]
  );

  const onContentTypeChange = React.useCallback(
    (ct) => {
      setFilterValues('contentTypes', ct);
    },
    [setFilterValues]
  );

  const onStudioVersionChange = React.useCallback(
    (ct) => {
      setFilterValues('studioVersions', ct);
    },
    [setFilterValues]
  );

  const onFlaggedEntitiesChange = React.useCallback(
    (ct) => {
      setFilterValues('flaggedEntities', ct);
    },
    [setFilterValues]
  );

  const onPublicationStateChange = React.useCallback(
    (states) => {
      setFilterValues('publicationState', states);
    },
    [setFilterValues]
  );

  if (filter.name === 'publishers') {
    return (
      <AuthorFilter
        value={filter.values as Author[]}
        onChange={onAuthorChange}
        useAuthorAliases={false}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'author_aliases') {
    return (
      <AuthorFilter
        value={filter.values as Author[]}
        onChange={onAuthorAliasChange}
        useAuthorAliases
        allowForgotten
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'creators') {
    return (
      <AuthorFilter
        value={filter.values as Author[]}
        onChange={onCreatorChange}
        searchPlaceholder="Search creators"
        triggerName="Creator"
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'topics') {
    return (
      <TopicsFilter
        value={filter.values as Topic[]}
        onChange={onTopicChange}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'initiatives') {
    return (
      <InitiativesFilter
        value={filter.values as string[]}
        onChange={onInitiativeChange}
        align={dropdownAlignment}
        autoResponsive={autoResponsive}
      />
    );
  }

  if (filter.name === 'content_types') {
    return (
      <ContentTypeFilter
        onChange={onContentTypeChange}
        value={filter.values as string[]}
        states={filter.states as ContentType[] | undefined}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'studio_versions') {
    return (
      <StudioVersionFilter
        onChange={onStudioVersionChange}
        value={filter.values as string[]}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'flagged_entities') {
    return (
      <FlaggedFilter
        onChange={onFlaggedEntitiesChange}
        value={filter.values as string[]}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'publication_state') {
    return (
      <PublicationStateFilter
        onChange={onPublicationStateChange}
        value={filter.values as string[]}
        states={filter.states as string[]}
        align={dropdownAlignment}
      />
    );
  }

  if (filter.name === 'publishDate' || filter.name === 'creationDate') {
    return <DateRangeFilter filter={(filter as unknown) as DateFilter} />;
  }

  // this shouldn't happen
  return <div>Unknown filter</div>;
};
