import { useProgram } from 'contexts/program';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { DefinitionBlock } from 'models/publisher/block';
import { useFlashMessage } from 'contexts/flasher';
import { v4 as uuidv4 } from 'uuid';
import { usePublisher } from 'contexts/publisher';

const MAX_VARIANTS = 10;

export type DynamicBlockDefinitionType = DefinitionBlock<{
  dynamic_block: { uuid: string; type: 'dynamic_block' };
}>;

export type UseDynamicBlockEditor = {
  currentVariant: DynamicBlockVariant;
  dynamicBlockVariants: DynamicBlockVariant[];
  currentIndex: number;
  selectPreviousVariant: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selectNextVariant: (e: React.MouseEvent<HTMLButtonElement>) => void;
  appendVariant: (
    e: React.MouseEvent<HTMLButtonElement>,
    source?: DynamicBlockVariant
  ) => void;
  updateName: (name: string) => void;
  isReady: boolean;
};

export const useDynamicBlockEditor: (
  block: DynamicBlockDefinitionType,
  currentIndex: number,
  setCurrentIndex: (index: number) => void
) => UseDynamicBlockEditor = (block, currentIndex, setCurrentIndex) => {
  const { id: programId } = useProgram();
  const { uuid } = block.field_data.dynamic_block;
  const { setFlashMessage } = useFlashMessage();
  const publisher = usePublisher();

  const { dynamicBlocks, update } = publisher.dynamicBlocks;
  const currentDynamicBlock = dynamicBlocks[uuid];

  const dynamicBlockVariants = currentDynamicBlock
    ? (currentDynamicBlock.dynamicBlockVariants || []).sort(
        (a, b) => a.order - b.order
      )
    : [];

  const currentVariant = dynamicBlockVariants[currentIndex];

  const isFirstVariantSelected = currentIndex === 0;
  const isLastVariantSelected =
    currentIndex === dynamicBlockVariants.length - 1;

  const selectPreviousVariant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const previousIndex = isFirstVariantSelected
      ? currentIndex
      : currentIndex - 1;
    setCurrentIndex(previousIndex);
  };

  const selectNextVariant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const nextIndex = isLastVariantSelected ? currentIndex : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  const appendVariant = (
    e: React.MouseEvent<HTMLButtonElement>,
    source?: DynamicBlockVariant
  ) => {
    e.stopPropagation();
    const { length } = dynamicBlockVariants;
    if (length >= MAX_VARIANTS) {
      setFlashMessage({
        severity: 'error',
        message: `You can only have ${MAX_VARIANTS} variants`,
      });
    } else {
      const fields = {
        order: isLastVariantSelected
          ? currentVariant.order
          : currentVariant.order + 1,
        uuid: uuidv4(),
        default: false,
      } as Partial<DynamicBlockVariant>;
      const newVariant = source
        ? {
            ...source,
            ...fields,
          }
        : ({
            uuid: uuidv4(),
            program_id: programId,
            dynamic_block_id: currentDynamicBlock.id,
            name: 'Untitled variant',
            ...fields,
          } as DynamicBlockVariant);

      const updatedVariants = isLastVariantSelected
        ? dynamicBlockVariants.map((variant) => {
            if (variant.uuid === currentVariant.uuid) {
              return { ...variant, order: newVariant.order + 1 };
            }
            return { ...variant };
          })
        : dynamicBlockVariants.map((variant) => {
            if (variant.order > currentVariant.order) {
              return { ...variant, order: variant.order + 1 };
            }
            return variant;
          });

      updatedVariants.push(newVariant);

      const orderedDynamicBlockVariants = updatedVariants.sort(
        (a, b) => a.order - b.order
      );

      const newIndex = orderedDynamicBlockVariants.findIndex(
        (v) => v.uuid === newVariant.uuid
      );
      update(uuid, { dynamicBlockVariants: orderedDynamicBlockVariants });
      setCurrentIndex(newIndex);
    }
  };

  const updateName = (name: string) => {
    const newVariants = dynamicBlockVariants.map((variant) =>
      variant.uuid === currentVariant.uuid ? { ...variant, name } : variant
    );
    update(uuid, { dynamicBlockVariants: newVariants });
  };

  if (!currentDynamicBlock) {
    return {
      currentVariant: {} as DynamicBlockVariant,
      dynamicBlockVariants: [],
      currentIndex,
      selectPreviousVariant: () => {},
      selectNextVariant: () => {},
      appendVariant: () => {},
      updateName: () => {},
      isReady: false,
    };
  }

  return {
    currentVariant,
    dynamicBlockVariants,
    currentIndex,
    selectPreviousVariant,
    selectNextVariant,
    appendVariant,
    updateName,
    isReady: !!currentDynamicBlock,
  };
};
