import snakecaseKeys from 'snakecase-keys';
import { DynamicBlock } from 'models/dynamic_blocks/dynamic_block';
import { DynamicBlockVariant } from 'models/dynamic_blocks/dynamic_block_variant';
import { deepCamelcaseKeys, request } from './api-shared';

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}/v2`;

export type DynamicBlockData = {
  data: {
    attributes: DynamicBlock;
  };
};

export type DynamicBlockMetadata = {
  data: {
    variants: DynamicBlockVariant[];
  };
};

export const fetchById = async (
  programId: number,
  id: string
): Promise<DynamicBlock> => {
  if (!id || id === 'new') throw new Error('No ID provided');
  const url = `${apiRoot}/programs/${programId}/dynamic_blocks/${id}`;
  const response = await request(url);

  if (response.status === 200) {
    const json = await response.json();
    const { data } = json;
    return deepCamelcaseKeys(data.attributes);
  }
  throw new Error(`Error fetching dynamic block: ${response.status}`);
};

export const upsertDynamicBlock = async (
  programId: number,
  dynamicBlock: Partial<DynamicBlock>
): Promise<DynamicBlock> => {
  const url = `${apiRoot}/programs/${programId}/dynamic_blocks${
    dynamicBlock.id ? `/${dynamicBlock.id}` : ''
  }`;

  const response = await request(url, {
    method: dynamicBlock.id ? 'PUT' : 'POST',
    body: JSON.stringify({
      data: {
        attributes: snakecaseKeys(dynamicBlock),
      },
    }),
  });

  if (response.status === 200 || response.status === 201) {
    return response
      .json()
      .then((output) => deepCamelcaseKeys(output.data.attributes));
  }

  throw new Error(`Error upserting dynamic block: ${response.status}`);
};

export async function destroy(id: number): Promise<void> {
  await request(`${apiRoot}/dynamic_blocks/${id}`, {
    method: 'DELETE',
  });
}

export async function fetchMetadata(
  program_id: number,
  uuid: string
): Promise<DynamicBlockMetadata> {
  const response = await request(
    `${apiRoot}/programs/${program_id}/dynamic_blocks/${uuid}/metadata`
  );
  return response
    .json()
    .then((output) => deepCamelcaseKeys(output.data.attributes));
}
